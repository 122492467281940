import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
} from '@angular/core';
import {
  Alert,
  AlertType,
} from '@model/notification/notification-model';
import { NotificationService } from '@service/notification.service';
import { NavigationComponent } from '../navigation/navigation.component';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(-100%)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class NotificationComponent implements AfterViewInit {
  // TODO createElement instead of Array
  alerts: Alert[] = [];
  cssClass = CSS_CLASSES;
  spinnerSet = false;
  spinner = false;


  constructor(private notify: NotificationService, private navigation: NavigationComponent) { }

  ngAfterViewInit(): void {
    // this.spinnerSet = true;
    this.notify
      .asObservable()
      .subscribe((alert: Alert) => {
        if (!alert) {
          // clear alerts when an empty alert is received
          this.alerts = [];
          return;
        }
        const a = alert as any;

        if (a.spinner === true) {
          this.startSpinner();
          return;
        }
        if (a.spinner === false) {
          this.stopSpinner();
          return;
        }
        this.stopSpinner();

        // add alert to array
        this.alerts.unshift(alert);
        let countDown = 10;
        const countdownInterval = setInterval(() => {
          if (countDown <= 1) {
            clearInterval(countdownInterval);
          }
          this.removeAlert(alert);
          countDown--;

        }, 5000);
      });
  }

  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter(x => x !== alert);
  }
  startSpinner() {
    this.spinnerSet = true;
    this.navigation.loading = true;
  }
  stopSpinner() {
    this.navigation.loading = false;
  }

}

const CSS_CLASSES = {
  [AlertType.Success]: 'notification success',
  [AlertType.Error]: 'notification danger',
  [AlertType.Info]: 'notification info',
  [AlertType.Warning]: 'notification warning',
};

