import { Component, ComponentFactoryResolver, ViewContainerRef, OnChanges, SimpleChanges, Input, Type } from '@angular/core';

@Component({
  selector: 'app-switcher-component',
  template: '<b>Inserted Component:</b>'
})
export class SwitcherComponent implements OnChanges {
  @Input() insertedComponent: Type<any>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const insertedComponent  = changes;
    if (insertedComponent.currentValue) {
      this.renderComponent();
    }
  }

  renderComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.insertedComponent);
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    componentRef.changeDetectorRef.detectChanges();
  }
}
