import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { BroadcasterService } from '@service/broadcaster.service';

import { DialogConfirmComponent } from './dialog-confirm.component';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(200)
      ]),
      transition('* => void', [
        animate(200, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class ConfirmDialogComponent implements OnInit {

  @Input() closable = true;
  @Input() visible: boolean;
  @Input() message: string;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  id;
  onConfirm: Function;

  constructor(private broadcast: BroadcasterService, public dialog: MatDialog) { }

  ngOnInit() {
    this.broadcast.on<any>(ConfirmDialogComponent)
      .subscribe(data => {
        this.message = data.message;
        this.onConfirm = data.callBack;
        this.openDialog();
      });
  }

  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  ok() {
    this.onConfirm();
    this.visible = false;
  }

  public openModal() {
    this.visible = true;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: '300px',
      data: this.message
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.ok();

      }
    });
  }
}

