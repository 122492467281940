import { ModelService } from '@service/model.service';
import { NgModelBase } from '../../../base-class/base-class';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-employee-type',
  templateUrl: './form-employee-type.component.html',
  styles: ['']
})
export class FormEmployeeTypeComponent extends NgModelBase implements OnInit {
  personnelTypeNameEN = new FormControl('', [Validators.required]);
  personnelTypeNameTH = new FormControl('', [Validators.required]);

  constructor(private modelService: ModelService) {
    super();
    const cptType = this.modelService.getValue();
    if (cptType.cptType.name === FormEmployeeTypeComponent.name) {
      this.value = cptType.data;
    }
  }

  ngOnInit() {
  }
  getErrorMessage(value: string) {
    switch (value) {
      case 'personnelTypeNameTH':
        return this.personnelTypeNameTH.hasError('required') ? 'ระบุชื่อประเภทพนักงาน (TH)' : '';
      // break;
      case 'personnelTypeNameEN':
        return this.personnelTypeNameEN.hasError('required') ? 'ระบุชื่อประเภทพนักงาน (EN)' : '';
      // break;
      default:
        break;
    }
  }
}
