import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-emp-card',
  templateUrl: './emp-card.component.html',
  styleUrls: ['./emp-card.component.scss']
})
export class EmpCardComponent implements OnInit {
  @Input() nameOnly = false;
  @Input() data;
  param;
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.param = this.route.snapshot.paramMap.get('id');
  }
  editData() {
    this.router.navigateByUrl(`/hrm/recruitment/application/edit/${this.param}`);
  }
}
