import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkNull'
})
export class CheckNullPipe implements PipeTransform {

  transform(value: any, args?: string): any {
    let a = 'ไม่ระบุ';
    if (args !== undefined) {
      a = args;
    }
    if (value === null || value === undefined || value === '') {
      return a;
    } else {
      return value;
    }

  }

}
