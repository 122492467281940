import { Component } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  Router,
} from '@angular/router';

import { Observable } from 'rxjs';
import {
  filter,
  map,
  startWith,
} from 'rxjs/operators';

interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  breadcrumb$: Observable<IBreadcrumb[]>;
  lastLabel: string;
  $viewport: JQuery;

  constructor(
    router: Router,
    route: ActivatedRoute,
  ) {
    this.$viewport = $('#viewport');
    this.breadcrumb$ = router
      .events
      .pipe(
        startWith(new NavigationEnd(null, null, null)),
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const breadcrumbs = resolve(route);
          const length = breadcrumbs.length;

          this.lastLabel = length ? breadcrumbs[length - 1].label : '';
          this.scrollToTop();

          return breadcrumbs;
        })
      );
  }

  // Scroll To Top
  scrollToTop() {
    if (!this.$viewport.scrollTop()) {
      return;
    }

    this.$viewport.animate({
      scrollTop: 0,
    }, 100);
  }

  onBackUrl() {

  }
}

const DATA_BREADCRUMB = 'breadcrumb';

function resolve(route: ActivatedRoute): IBreadcrumb[] {
  const breadcrumbs: IBreadcrumb[] = [];
  let url = '';

  while (route = route.firstChild) {
    const { routeConfig, snapshot } = route;
    const label = routeConfig.data && routeConfig.data[DATA_BREADCRUMB];

    for (const segment of snapshot.url) {
      url += `/${segment.path}`;
    }

    if (label) {
      breadcrumbs.push({
        label,
        url,
        params: snapshot.params,
      });
    }
  }

  return breadcrumbs;
}
