import { registerLocaleData } from '@angular/common';
import localeThExtra from '@angular/common/locales/extra/th';
import localeTH from '@angular/common/locales/th';
import {
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './core/base-component/navigation/navigation.component';
import { CoreModule } from './core/core.module';

import 'hammerjs';

registerLocaleData(localeTH, getLocale, localeThExtra);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
  ],
  declarations: [
    AppComponent,
    NavigationComponent,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: getLocale
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useFactory: getMatDialogDefaultOptions
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useFactory: getMatFormFieldDefaultOptions
    }
    // { provide: MAT_DATE_LOCALE, useValue: 'th-TH' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function getLocale() {
  const locale = 'th-TH';
  return locale;
  // return `${locale}-u-ca-gregory`;
}

export function getMatDialogDefaultOptions() {
  return {
    autoFocus: true,
    closeOnNavigation: true,
    data: null,
    disableClose: true,
    hasBackdrop: true,
    maxWidth: '80vw',
    restoreFocus: true,
    role: 'dialog',
  };
}

export function getMatFormFieldDefaultOptions() {
  return { appearance: 'outline' };
}


