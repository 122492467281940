import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PipesCustomModule } from '../../pipes/pipes-custom.module';
import { MaterialModule } from '../material/mat.module';
import { CustomSelect2Module } from '../select2/customSelect2.module';
import { AddressDetailComponent } from './address-detail/address-detail.component';
import { ApplicationDetailComponent } from './application-detail/application-detail.component';
import { CardMiniJobComponent } from './card-mini-job/card-mini-job.component';
import { ChartContainerComponent } from './chart-container/chart-container.component';
import { ContactDetailComponent } from './contact-detail/contact-detail.component';
import { CustomFormAddressComponent } from './custom-form-address/custom-form-address.component';
import { CustomFormContactComponent } from './custom-form-contact/custom-form-contact.component';
import { EmpCardComponent } from './emp-card/emp-card.component';
import { EmpLangComponent } from './emp-lang/emp-lang.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    CustomSelect2Module,
    PipesCustomModule,
  ],
  declarations: [
    ContactDetailComponent,
    AddressDetailComponent,
    CustomFormContactComponent,
    CustomFormAddressComponent,
    EmpCardComponent,
    ApplicationDetailComponent,
    CardMiniJobComponent,
    ChartContainerComponent,
    EmpLangComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    CustomSelect2Module,
    PipesCustomModule,

    ContactDetailComponent,
    AddressDetailComponent,
    CustomFormContactComponent,
    CustomFormAddressComponent,
    EmpCardComponent,
    ApplicationDetailComponent,
    CustomSelect2Module,
    CardMiniJobComponent,
    ChartContainerComponent,
    EmpLangComponent,
  ]
})
export class ComponentsModule { }
