export class PagingModel {
    public cuerrntPage: number;
    public totalPerPage: number;
    public totalDataSize: number;

    public constructor(object: any) {
        this.cuerrntPage = object.cuerrntPage;
        this.totalDataSize = object.totalDataSize;
        this.totalPerPage = object.totalPerPage;
    }
}
