import {
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Select2Data } from '@modules/select2';
import { ModelService } from '@service/model.service';

import { NgModelBase } from '../../../base-class/base-class';

export const EPANDED_FORM_DEPARTMENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FormOrgDepartmentComponent),
  multi: true
};
@Component({
  selector: 'app-forms',
  templateUrl: './form-org-department.component.html',
  styles: [
    `mat-form-field {
    width: 100%;
  }`
  ],
  providers: [EPANDED_FORM_DEPARTMENT_VALUE_ACCESSOR]
})
export class FormOrgDepartmentComponent extends NgModelBase implements OnInit {
  departmentNameTH = new FormControl('', [Validators.required]);
  departmentNameEN = new FormControl('', [Validators.required]);
  departmentParentStartValue;
  public orgList: Select2Data[];
  public departmentList: Select2Data[];

  constructor(private modelService: ModelService) {
    super();
    const cptType = this.modelService.getValue();
    if (cptType.cptType.name === FormOrgDepartmentComponent.name) {
      this.value = cptType.data;
    }
  }

  ngOnInit() {

  }

  setOrgid($event) {
    this.value.organizationId = parseFloat($event);
  }
  setDepartid($event) {
    this.value.departmentParent = parseFloat($event);
  }
  getErrorMessage(value: string) {
    switch (value) {
      case 'departmentNameTH':
        return this.departmentNameTH.hasError('required') ? 'ระบุชื่อหน่วยงาน (TH)' : '';
      // break;
      case 'departmentNameEN':
        return this.departmentNameEN.hasError('required') ? 'ระบุชื่อหน่วยงาน (EN)' : '';
      // break;
      default:
        break;
    }
  }
}
