export class Session {
  private token = '';
  private newNotification = 0;
  private qtyDocumentApprovePending = 0;
  private timer: any;

  constructor() {
    this.sync(localStorage.getItem(SESSION));

    window.onstorage = ({ key, newValue }) => {
      if (key !== SESSION) {
        return;
      }

      if (newValue) {
        this.sync(newValue);
      } else {
        this.destroy();
      }
    };
  }

  get<T = any>(property: Property): T {
    return this[property];
  }

  set(property: Property, value: any) {
    if (typeof this.timer === 'number') {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.timer = null;
      localStorage.setItem(SESSION, JSON.stringify(this));
    }, 100);

    return this[property] = value;
  }

  destroy() {
    localStorage.removeItem(SESSION);

    this.token = '';
    this.newNotification = 0;
    this.qtyDocumentApprovePending = 0;
  }

  private sync(json: string) {
    try {
      Object.assign(this, JSON.parse(json));
    } catch (e) {
      console.error(e);
    }
  }
}

export type Property = keyof User | keyof Notify;

const SESSION = 'session';

interface User {
  token: string;
  userId: any;
  userType: any;
  userPersonId: any;
  userEmployment: any;
  userEmploymentId: any;
  userFirstnameEN: string;
  userFirstnameTH: string;
  userLastnameEN: string;
  userLastnameTH: string;
  userPersonCode: string;
}

interface Notify {
  newNotification: number;
  qtyDocumentApprovePending: number;
}
