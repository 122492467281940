import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  Validators,
} from '@angular/forms';
import { ModelService } from '@service/model.service';

import { NgModelBase } from '../../../base-class/base-class';

@Component({
  selector: 'app-form-org-position',
  templateUrl: './form-org-position.component.html'
})
export class FormOrgPositionComponent extends NgModelBase implements OnInit {
  positionNameTH = new FormControl('', [Validators.required]);
  positionNameEN = new FormControl('', [Validators.required]);
  constructor(private modelService: ModelService) {
    super();
    const cptType = this.modelService.getValue();
    if (cptType.cptType.name === FormOrgPositionComponent.name) {
      this.value = cptType.data;
    }
  }

  ngOnInit() {

  }

  setOrgid($event) {
    this.value.organizationId = parseFloat($event);
  }
  setEmpid($event) {
    this.value.employeeLevelId = parseFloat($event);
  }
  getErrorMessage(value: string) {
    switch (value) {
      case 'positionNameTH':
        return this.positionNameTH.hasError('required') ? 'ระบุชื่อตำแหน่ง (TH)' : '';
      // break;
      case 'positionNameEN':
        return this.positionNameEN.hasError('required') ? 'ระบุชื่อตำแหน่ง (EN)' : '';
      // break;
      default:
        break;
    }
  }
}
