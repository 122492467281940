import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-master',
  templateUrl: './form-master.component.html',
  styleUrls: ['./form-master.component.scss']
})
export class FormMasterComponent implements OnInit {

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSubmit = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onCancel = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  public onSubmitClick() {
    this.onSubmit.emit(null);
  }

  public onCancelClick() {
    this.onCancel.emit(null);
  }

}
