import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'marryStatus'
})
export class MarryStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (value === 'single' || value === '1') {

      return 'โสด';
    } else if (value === 'married' || value === '2') {

      return 'แต่งงาน';
    } else if (value === 'widow' || value === '3') {

      return 'หม้าย';
    } else if (value === 'divorce' || value === '4') {

      return 'แยกกันอยู่';
    } else { return 'ไม่ระบุ'; }
  }
}
