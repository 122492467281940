import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, forwardRef } from '@angular/core';
import { NgModelBase } from '../../base-class/base-class';

export const EPANDED_CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EmpLangComponent),
  multi: true
};

@Component({
  selector: 'app-emp-lang',
  templateUrl: './emp-lang.component.html',
  styleUrls: ['./emp-lang.component.scss'],
  providers: [EPANDED_CUSTOM_VALUE_ACCESSOR]
})
export class EmpLangComponent extends NgModelBase implements OnInit {

  ngOnInit() {
  }
  getProgressBar(value) {
    const calWidth = value * 33.33;
    return `${calWidth}%`;
  }
  getProgressText(value) {
    switch (value) {
      case 3:
        return 'ดี';
      case 2:
        return 'ปานกลาง';
      case 1:
        return 'พอใช้';
      default:
        return '';
    }
  }
}
