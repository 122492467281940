import {
  Component,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-component',
  styleUrls: ['./confirm-dialog.component.scss'],
  templateUrl: './dialog-confirm-component.html',
})
export class DialogConfirmComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { }
}
