import {
  Directive,
  ElementRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ngModel][required]'
})
export class RequiredDirective {
  constructor(
    private el: ElementRef,
    private ngControl: NgControl,
  ) { }

  focus() {
    this.el.nativeElement.focus();
  }

  isInvalid() {
    this.ngControl.control.markAsTouched();

    return this.ngControl.invalid;
  }
}
