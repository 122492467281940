import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-address-detail',
  templateUrl: './address-detail.component.html',
  styles: [`mat-form-field {
    width: 100%;
}`]
})
export class AddressDetailComponent implements OnInit {

  @Input() data;

  address;
  province;
  amphur;
  district;
  ngOnInit() {
    if (this.data !== undefined) {
      // this.address = await this.fetch(this.data)
      this.address = {
        no: this.data.no,
        village: this.data.village,
        road: this.data.road,
        alley: this.data.alley,
        section: this.data.section,
        postcode: this.data.postcode
      };
      this.province = this.data.province;
      this.amphur = this.data.amphur;
      this.district = this.data.district;
    }
  }

  fetch(id) {

    // return this.ERPAPI.get('hr/address/' + id).then(res => res.object)
  }
}
