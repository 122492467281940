import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workStatus'
})
export class WorkStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 1:
        return 'ปกติ';
      case 2:
        return 'พักงานชั่วคราว';
      case 3:
        return 'ลาออก';
      case 4:
        return 'เชิญออก';
      case 5:
        return 'ไล่ออก';
      case 6:
        return 'เสียชีวิต';
      default:
        return '';
    }
  }
}
