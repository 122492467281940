
export class MenuModel {
    routerLink: string;
    text: string;
    icon: string;

    public constructor(data: any) {
        this.routerLink = data.routerLink;
        this.text = data.text;
        this.icon = data.icon;
    }
}
