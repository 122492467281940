import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { unsubscribe } from '@modules/utility';

import {
  isObservable,
  Observable,
  Subscription,
} from 'rxjs';

@Component({
  selector: 'app-chart-container',
  template: '',
  styles: [`
    :host {
      position: relative;
      display: flex;
    }
  `]
})
export class ChartContainerComponent implements OnDestroy {
  @Input('dataSource')
  set DataSource(source: any) {
    if (isObservable(source)) {
      this.subscription = (source as Observable<OrgChartData>)
        .subscribe(dataSource => {
          this.orgchart.opts.data = dataSource;
          this.orgchart.init(this.orgchart.opts);
        });
    } else {
      this.orgchart.opts.data = source || {};
      this.orgchart.init(this.orgchart.opts);
    }
  }

  private orgchart: any;
  private subscription: Subscription;


  constructor(el: ElementRef) {
    this.orgchart = ($(el.nativeElement) as any)
      .orgchart({
        nodeTitle: 'title',
        nodeContent: 'content',
        exportButton: true,
        exportFilename: 'MyOrgChart',
        exportFileextension: 'pdf',
        data: {},
      });
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }
}

export interface OrgChartData {
  name: string;
  content: string;
  children: OrgChartData[];
}
