import { Injectable } from '@angular/core';

import {
  Observable,
  Subject,
} from 'rxjs';
import {
  filter,
  map,
} from 'rxjs/operators';

interface BroadcastEvent {
  key: any;
  data?: any;
  request?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class BroadcasterService {
  private _eventBus: Subject<BroadcastEvent>;
  constructor() {
    this._eventBus = new Subject<BroadcastEvent>();
  }

  broadcast(key: any, data?: any) {
    this._eventBus.next({ key, data });
  }

  on<T>(key: any): Observable<T> {
    return this._eventBus.asObservable()
      .pipe(
        filter(event => event.key === key),
        map(event => <T>event.data));
  }

  onAll<T>(): Observable<T> {
    return this._eventBus.asObservable()
      .pipe(
        map(event => <T>event.data));
  }

}
