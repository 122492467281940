import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  API,
  session,
  SOCKJS,
} from '@environment';
import { NotificationModel } from '@model/base/config/NotificationModel';
import { MenuModel } from '@model/navigation/navigation-model';
import {
  getAPI,
  OrderBy,
  searchAPI,
} from '@modules/backend';
import { unsubscribe } from '@modules/utility';

import {
  Observable,
  Subscription,
} from 'rxjs';
import {
  filter,
  map,
} from 'rxjs/operators';

const PRELOAD_TRIGGER = trigger('preloading', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.5s 1s', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('.5s', style({ opacity: 0 }))
  ])
]);

declare const Stomp;
declare const SockJS;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    PRELOAD_TRIGGER
  ]
})
export class NavigationComponent {
  picture: any;

  loading: boolean;
  session = session;

  dataSourceNotification$: Observable<NotificationModel[]>;
  totalItem: number;

  tabStatus = 2;

  private currentPage = 1;
  private orderBy: OrderBy;

  private stomp: any;
  private socket: WebSocket;
  private subscription: Subscription;

  public menus = [
    new MenuModel(
      {
        routerLink: '/permission',
        text: 'ตั้งค่าระบบ',
        icon: 'assets/icon/files/setup.svg'
      }
    ),

  ];

  constructor(private router: Router) {
    router.events
      .pipe(
        filter(event => {
          return (
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel
          );
        })
      )
      .subscribe(event => {
        const loading = this.loading = event instanceof NavigationStart;

        if (!loading && session.get('token')) {
          // this.onLoadNotification();
        }
      });

    // this.connect();
  }

  onNotification() {
    this.dataSourceNotification$ = searchAPI<NotificationModel>(API.notification, {
      criteria: {
        'personnelId': session.get('userPersonId')
      },
      pagination: {
        currentPage: this.currentPage,
        limitItem: 20
      },
      orderBy: this.orderBy
    }).pipe(
      map((res: any) => {
        this.totalItem = res.pagination.totalItem;

        return res.object;
      })
    );
  }

  routerLink(url) {
    this.router.navigateByUrl(url);
  }

  private connect() {
    this.socket = new SockJS(SOCKJS.hr);
    this.stomp = Stomp.over(this.socket);

    const authen = {
      login: 'gg',
      passcode: 'gg'
    };

    this.stomp
      .connect(
        authen,
        () => this.stomp.subscribe(`/queue/notify/${session.get('userPersonId')}`, e => this.onMessage(e)),
        () => setTimeout(() => this.connect(), 2000)
      );
  }


  onMessage(data) {
    const object = JSON.parse(data.body);

    if (object) {
      session.set('newNotification', object.qtyNotification);
      session.set('qtyDocumentApprovePending', object.qtyDocumentApprovePending);

      this.onNotification();
    }
  }

  onLoadNotification() {
    unsubscribe(this.subscription);
    this.subscription = getAPI<any>(
      API.notification,
      'websocket'
    )
      .pipe(
        filter(({ object }) => !!object)
      )
      .subscribe(({ object }) => {
        session.set('newNotification', object.qtyNotification);
        session.set('qtyDocumentApprovePending', object.qtyDocumentApprovePending);
      });
  }
}
