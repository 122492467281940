import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idcard'
})
export class IdcardPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === undefined || value === null) {
      return;
    }
    let a = value.toString();
    a = a.replace(/-/g, '');
    if (a.length < 2 || a.length > 18) {
      return a;
    }
    let b = '';
    let s1 = '';
    let s2 = '';
    let s3 = '';
    let s4 = '';
    let s5 = '';
    if (a.length > 0) {
      s1 = a.slice(0, 1 - a.length);
      b += `${s1}`;
    }
    if (a.length > 1) {
      const r = a.length > 5 ? 5 : a.length;
      s2 = a.slice(1, r);
      b += `-${s2}`;
    }
    if (a.length > 5) {
      const r = a.length > 10 ? 10 : a.length;
      s3 = a.slice(5, r);
      b += `-${s3}`;
    }
    if (a.length > 10) {
      const r = a.length > 12 ? 12 : a.length;
      s4 = a.slice(10, r);
      b += `-${s4}`;
    }
    if (a.length > 12) {
      s5 = a.slice(12);
      b += `-${s5}`;
    }
    return b;
    // const s1 = a.slice(0, -12);
    // const s2 = a.slice(1, 5);
    // const s3 = a.slice(5, 10);
    // const s4 = a.slice(10, 12);
    // const s5 = a.slice(12);
    // a = `${s1}-${s2}-${s3}-${s4}-${s5}`;
    // return a;
  }

}
