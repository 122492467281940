import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { API } from '@environment';
import {
  postAPI,
  updateAPI,
} from '@modules/backend';
import { Select2Data } from '@modules/select2';
import { NotificationService } from '@service/notification.service';

import { NgModelBase } from '../../base-class/base-class';

@Component({
  selector: 'app-custom-form-address',
  templateUrl: './custom-form-address.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CustomFormAddressComponent,
    multi: true
  }],
  styles: [`
    mat-form-field {
      width: 100%;
    }
  `]
})
export class CustomFormAddressComponent extends NgModelBase {
  private _formType = 'edit';
  private _editing = false;

  set editing(editing: boolean) {
    this._editing = editing;
    if (editing && !this.province) {
      this.fetchProvince();
    }
  }
  get editing(): boolean { return this._editing; }
  @Input()
  set formType(formType: string) {
    this._formType = formType;
    if (formType === 'edit' && !this.province) {
      this.fetchProvince();
    }
  }
  get formType(): string { return this._formType; }

  @Output() updateSuccess: EventEmitter<boolean> = new EventEmitter();
  province: Select2Data[];
  district: Select2Data[];
  subDistrict: SubDistrict[];
  DataProvinceId = [];
  DataDistrictd = [];
  constructor(private notify: NotificationService) {
    super();
  }
  setDataProvince(id, index) {
    if (this.DataProvinceId[index] !== id && id) {
      this.fetchAmphur(id);
    }
    if (!this.innerValue[index].province) {
      this.innerValue[index].province = { provinceId: null };
    }
    if (!this.innerValue[index].district) {
      this.innerValue[index].district = { districtId: null };
    }
    if (!this.innerValue[index].amphur) {
      this.innerValue[index].amphur = { amphurId: null };
    }
    if (!this.innerValue[index].addressType) {
      this.innerValue[index].addressType = { addressTypeId: 1 };
    }
    this.DataProvinceId[index] = id;
    return id;
  }
  setDataDistrict(id, index) {
    if (this.DataDistrictd[index] !== id && id) {
      this.fetchDistrict(id);
    }
    this.DataDistrictd[index] = id;
    return id;
  }
  fat() {
  }
  fetchProvince() {
    if (this.province) {
      return;
    }
    postAPI(API.province)
      .subscribe(({ object }) => {
        this.province = object
          .provinceList
          .map(item => {
            return {
              id: item.provinceId,
              text: item.provinceNameTH
            };
          });
      });
  }

  fetchAmphur(provinceId: any) {
    postAPI(API.amphur, { provinceId })
      .subscribe(({ object }) => {
        this.district = object
          .amphurList
          .map(item => {
            return {
              id: item.amphurId,
              text: item.amphurNameTH
            };
          });
      });
  }

  fetchDistrict(amphurId: any) {
    postAPI(API.district, { amphurId })
      .subscribe(({ object }) => {
        this.subDistrict = object
          .districtList
          .map(item => {
            return {
              id: item.districtId,
              text: item.districtNameTH,
              postCode: item.postcode,
            };
          });
      });
  }
  onUpdate(index) {
    updateAPI(API.address, this.value[index])
      .subscribe(this.catchResult('บันทึกข้อมูลที่อยู่'));
  }


  private catchResult(message: string) {
    this.notify.info(`กำลัง${message}`);

    return {
      next: () => {
        this.editing = false;
        this.updateSuccess.emit(true);
        this.notify.success(`${message} แล้ว`);
      },
      error: () => this.notify.error(`ไม่สามารถ${message}`)
    };
  }
}

interface SubDistrict extends Select2Data {
  postCode: number;
}
