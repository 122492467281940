import { Directive } from '@angular/core';
import { MatDatepickerInput } from '@angular/material';

// TODO I/O with timestamp
@Directive({
  selector: 'input[matDatepicker]'
})
export class TimestampDirective {
  constructor(matDatepickerInput: MatDatepickerInput<any>) {
    const input = matDatepickerInput;
    const registerOnChange = input.registerOnChange;
    const writeValue = input.writeValue;

    // @Override RegisterOnChange
    input.registerOnChange = fn => {
      registerOnChange.call(input, v => fn(v && v.getTime()));
    };

    // @Override WriteValue
    input.writeValue = v => {
      writeValue.call(input, new Date(v));
    };
  }
}
