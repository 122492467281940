import {
  Component,
  OnInit,
} from '@angular/core';
import { Select2Data } from '@modules/select2';
import { ModelService } from '@service/model.service';

import { NgModelBase } from '../../../base-class/base-class';

@Component({
  selector: 'app-form-holiday',
  templateUrl: './form-holiday.component.html'
})
export class FormHolidayComponent extends NgModelBase implements OnInit {
  holidayTypeData: Select2Data[];
  holidayList = [{}];
  holidayAgoList = [{}];
  holidayYearData = [];
  holidayAddOptional = '1';

  constructor(private modelService: ModelService) {
    super();
    const cptType = this.modelService.getValue();
    if (cptType.cptType.name === FormHolidayComponent.name) {
      this.value = cptType.data;
    }
  }

  ngOnInit() {
    this.holidayTypeData = [
      { id: '', text: 'วันหยุดประจำปี' },
      { id: '', text: 'วันหยุดราชการ' },
      { id: '', text: 'วันหยุดนักขัตฤกษ์' },
      { id: '', text: 'วันหยุดทั่วไป' },
      { id: '', text: 'วันหยุดชดเชย' },
      { id: '', text: 'วันหยุดเฉพาะกิจ' },
    ];
    this.holidayYearData = [
      { id: '', text: '2017' },
      { id: '', text: '2016' },
      { id: '', text: '2015' },
      { id: '', text: '2014' }
    ];
  }

  pushHolidayList() {
    this.holidayList.push({
      address: { id: 0 }, file: {}
    });
  }
}
