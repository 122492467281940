import {
  Pipe,
  PipeTransform,
} from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === null || value === '') {
      return value;
    }
    let d;
    if (typeof value === 'string') {
      d = value.replace(/,/g, '');
    } else {
      d = value;
    }
    const a = parseFloat(d);
    const b = new Intl.NumberFormat().format(a);
    return b.toString();
  }

}
