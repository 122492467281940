import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'นาย' || 1 || '1':
        return 'Mr';
      case 'นาง' || 2 || '2':
        return 'Mrs';
      case 'น.ส.' || 3 || '3':
        return 'Miss';
      default:
        return '';
    }
  }

}
