import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yearDiff'
})
export class YearDiffPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return `ไม่ระบุ`;

    }
    const dateStart = new Date(value);
    const dateEnd = args === undefined ? new Date() : args[0] === null || args[0] === undefined ? new Date() : new Date(args[0]);
    const timeDiff = Math.abs(dateStart.getTime() - dateEnd.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const a = Math.floor(diffDays / 365);
    if (args !== undefined) {
      if (args[1] !== undefined) {
        switch (args[1]) {
          case 'd':
            return `${diffDays} วัน`;
          case 'n':
            return diffDays;
          default:
            return `${a} ปี`;
        }
      }
    }
    if (diffDays < 365) {
      return `${diffDays} วัน`;
    }
    if (diffDays < 365) {
      return `${diffDays} วัน`;
    }
    return `${a} ปี`;

  }
}
