import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'militaryStatus'
})
export class MilitaryStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === 'ได้รับยกเว้น' || value === '1') {

      return 'ได้รับยกเว้น';
    } else if (value === '2' || value === 'ปลดเป็นทหารกองหนุน') {

      return 'ปลดเป็นทหารกองหนุน';
    } else if (value === 'ยังไม่ได้รับการเกณฑ์' || value === '3') {

      return 'ยังไม่ได้รับการเกณฑ์';
    } else if (value === 'อื่นๆ' || value === '4') {

      return 'อื่นๆ';
    } else {
      return 'ไม่ระบุ';
    }
  }

}
