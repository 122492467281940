import {
  Attribute,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Select2Data } from '@modules/select2';

import { NgModelBaseSelect } from '../../base-class/select2-base-class';

@Component({
  selector: 'app-unit-select2',
  template: `
    <div class="field">
      <mat-label *ngIf="hiddenLabel == null">
      หน่วยนับ
        <span
          *ngIf="required != null"
          class="text-danger"
        >*</span>
      </mat-label>
      <select2
        [(ngModel)]="values"
        (change)="change.emit($event)"
        [dataSource]="dataSource$"
        [placeholder]="placeholder"
        [attr.allowclear]="allowclear"
      ></select2>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UnitSelect2Component,
      multi: true
    }
  ]
})
export class UnitSelect2Component extends NgModelBaseSelect {
  @Output()
  change = new EventEmitter<Select2Data>();

  dataSource$ = ORG_SELECT2$;

  constructor(
    @Attribute('allowclear')
    public allowclear: string,
    @Attribute('hiddenLabel')
    public hiddenLabel: string,
    @Attribute('placeholder')
    public placeholder: string,
    @Attribute('required')
    public required: string,
  ) {
    super();
  }
}

// export const ORG_SELECT2$ = searchAPI<OrganizationModel>(API.organization)
//   .pipe(
//     map(({ object }) => {
//       const result = [];

//       for (const data of object) {
//         result.push({
//           id: data.organizationId,
//           text: data.organizationNameTH
//         });
//       }

//       return <Select2Data[]>result;
//     })
//   );

export const ORG_SELECT2$ = [
  {
    id: 1,
    text: 'cm'
  },
  {
    id: 1,
    text: 'kg'
  },
  {
    id: 1,
    text: 'm'
  },
  {
    id: 1,
    text: 'mm'
  }
];
