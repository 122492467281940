import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let text;
    switch (args) {
      case 'statusInternships':
        switch (value) {
          case 1:
            text = 'ทดลองงาน';
            break;
          case 2:
            text = 'ผ่านทดลองงาน';
            break;
          case 3:
            text = 'ไม่ผ่านทดลองงาน';
            break;
          case 4:
            text = 'ไม่ต้องทดลองงาน';
            break;
          default:
            text = 'สถานะไม่ถูกต้อง';
            break;
        }
        break;

      case 'statusDayofWeek':
        switch (value) {
          case 1:
            text = 'วันจันทร์';
            break;
          case 2:
            text = 'วันอังคาร';
            break;
          case 3:
            text = 'วันพุธ';
            break;
          case 4:
            text = 'วันพฤหัสบดี';
            break;
          case 5:
            text = 'วันศุกร์';
            break;
          case 6:
            text = 'วันเสาร์';
            break;
          default:
            text = 'วันอาทิตย์';
            break;
        }
        break;

      default:
        text = 'สถานะไม่ถูกต้อง';
        break;
    }
    return text;
  }
}
