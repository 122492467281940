import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material';
import { Select2Module } from '@modules/select2';

import { DirectivesCustomModule } from '../../directives/directives-custom.module';
import { CustomInputComponent } from './base-select2/custom-input.component';
import { DepartmentsSelect2Component } from './base-select2/departments-select2.component';
import { LeaveGroupSelect2Component } from './base-select2/leavegroup-select2.component';
import { LeaveTypeSelect2Component } from './base-select2/leavetype-select.component';
import { OrgSelect2Component } from './base-select2/org-select2.component';
import { PersonnelTypeTypeSelect2Component } from './base-select2/personnel-type-select2.component';
import { PersonnelWorkStatussSelect2Component } from './base-select2/personnel-workstatus-select2.component';
import { PositionsSelect2Component } from './base-select2/positions-select2.component';
import { VehicleBrandSelect2Component } from './base-select2/vehicle-brand-select2.component';
import { MaintenanceTypeSelect2Component } from './base-select2/maintenance-type-select.component';
import { UnitSelect2Component } from './base-select2/unit-select2.component';
import { VehicleModelSelect2Component } from './base-select2/vehicle-model-select2.component';
import { VehicleTypeSelect2Component } from './base-select2/vehicle-type-select2.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    Select2Module,
    DirectivesCustomModule
  ],
  declarations: [
    OrgSelect2Component,
    CustomInputComponent,
    DepartmentsSelect2Component,
    PositionsSelect2Component,
    PersonnelWorkStatussSelect2Component,
    LeaveGroupSelect2Component,
    PersonnelTypeTypeSelect2Component,
    LeaveTypeSelect2Component,
    VehicleBrandSelect2Component,
    VehicleModelSelect2Component,
    VehicleTypeSelect2Component,
    MaintenanceTypeSelect2Component,
    UnitSelect2Component
  ],
  exports: [
    CommonModule,
    MatInputModule,
    Select2Module,
    DirectivesCustomModule,
    OrgSelect2Component,
    CustomInputComponent,
    DepartmentsSelect2Component,
    PositionsSelect2Component,
    PersonnelWorkStatussSelect2Component,
    LeaveGroupSelect2Component,
    PersonnelTypeTypeSelect2Component,
    VehicleModelSelect2Component,
    VehicleTypeSelect2Component,
    LeaveTypeSelect2Component,
    VehicleBrandSelect2Component,
    MaintenanceTypeSelect2Component,
    UnitSelect2Component
  ]
})
export class CustomSelect2Module { }
