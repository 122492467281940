import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Select2Module } from '@modules/select2';

import { MaterialModule } from '../../../material/mat.module';
import { CustomSelect2Module } from '../../../select2/customSelect2.module';
import { FormSearchComponent } from './form-search.component';

@NgModule( {
  declarations: [
    FormSearchComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule.withConfig( { warnOnNgModelWithFormControl: 'never' } ),
    MaterialModule,
    Select2Module,
    CustomSelect2Module
  ],
  exports: [
    FormSearchComponent
  ],
  providers: [],
} )
export class FormSearchModule { }
