import { ModelService } from '@service/model.service';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { NgModelBase } from '../../../base-class/base-class';

@Component({
  selector: 'app-form-leave-type',
  templateUrl: './form-leave-type.component.html',
  styleUrls: ['./form-leave-type.component.css']
})
export class FormLeaveTypeComponent extends NgModelBase implements OnInit {
  leaveTypeNameEN = new FormControl('', [Validators.required]);
  leaveTypeNameTH = new FormControl('', [Validators.required]);

  constructor(private modelService: ModelService) {
    super();
    const cptType = this.modelService.getValue();
    if (cptType.cptType.name === FormLeaveTypeComponent.name) {
      this.value = cptType.data;
    }
  }

  ngOnInit() {
  }

  getErrorMessage(value: string) {
    switch (value) {
      case 'leaveTypeNameTH':
        return this.leaveTypeNameTH.hasError('required') ? 'ระบุชื่อประเภทพนักงาน (TH)' : '';
      // break;
      case 'leaveTypeNameEN':
        return this.leaveTypeNameEN.hasError('required') ? 'ระบุชื่อประเภทพนักงาน (EN)' : '';
      // break;
      default:
        break;
    }
  }
}
