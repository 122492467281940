import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { QuillModule } from 'ngx-quill';

import { BasePageComponent } from './base-component/base-page/base-page.component';
import { BreadcrumbComponent } from './base-component/breadcrumb/breadcrumb.component';
import { ComponentsModule } from './base-component/components/components.module';
import { ConfirmDialogComponent } from './base-component/confirm-dialog/confirm-dialog.component';
import { DialogConfirmComponent } from './base-component/confirm-dialog/dialog-confirm.component';
import { FormOrgDepartmentComponent } from './base-component/forms/component/form-org-department/form-org-department.component';
import { FormMasterModule } from './base-component/forms/form.module';
import { AutoCompleteComponent } from './base-component/input/auto-complete/auto-complete.component';
import { MaterialModule } from './base-component/material/mat.module';
import { MessageComponent } from './base-component/message/message.component';
import {
  DialogComponent,
  ModalComponent,
} from './base-component/modal/modal.component';
import { NotificationComponent } from './base-component/notification/notification.component';
import { PagingComponent } from './base-component/paging/paging.component';
import { SlideBarComponent } from './base-component/slide-bar/slide-bar.component';
import { SwitcherComponent } from './base-component/switcher/switcher.component';
// import { TableComponent } from './base-component/table/table.component';
import { DirectivesCustomModule } from './directives/directives-custom.module';
import { PipesCustomModule } from './pipes/pipes-custom.module';

@NgModule({
  imports: [
    FlexLayoutModule,
    MaterialModule,
    FormMasterModule,
    QuillModule,
    ComponentsModule,
    DirectivesCustomModule,
    PipesCustomModule,
  ],
  declarations: [
    // TableComponent,
    PagingComponent,
    BasePageComponent,
    MessageComponent,
    SlideBarComponent,
    BreadcrumbComponent,
    ConfirmDialogComponent,
    AutoCompleteComponent,
    NotificationComponent,
    SwitcherComponent,
    ModalComponent,
    DialogComponent,
    DialogConfirmComponent,
  ],
  exports: [
    FlexLayoutModule,
    MaterialModule,
    FormMasterModule,
    QuillModule,
    ComponentsModule,
    DirectivesCustomModule,
    PipesCustomModule,

    // TableComponent,
    PagingComponent,
    SlideBarComponent,
    MessageComponent,
    MaterialModule,
    BreadcrumbComponent,
    ConfirmDialogComponent,
    AutoCompleteComponent,
    NotificationComponent,
    ModalComponent,
    DialogComponent,
    DialogConfirmComponent,
  ],
  entryComponents: [
    FormOrgDepartmentComponent,
    DialogComponent,
    DialogConfirmComponent
  ]
})
export class CoreModule { }
