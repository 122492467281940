import {
  Attribute,
  Component,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { API } from '@environment';
import { LeaveTypeModel } from '@model/base/hr/LeaveTypeModel';
import { searchAPI } from '@modules/backend';
import { Select2Data } from '@modules/select2';

import { NgModelBaseSelect } from '../../base-class/select2-base-class';

@Component({
  selector: 'app-leave-type-select2',
  template: `
    <div class="field">
      <mat-label *ngIf="hiddenLabel == null">
        ประเภทการลา
        <span
          *ngIf="required != null"
          class="text-danger"
        >*</span>
      </mat-label>
      <select2
        [(ngModel)]="values"
        [dataSource]="dataSource"
        [placeholder]="placeholder"
        [attr.allowclear]="allowclear"
      ></select2>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LeaveTypeSelect2Component,
      multi: true
    }
  ]
})
export class LeaveTypeSelect2Component extends NgModelBaseSelect {
  dataSource: Select2Data[];

  constructor(
    @Attribute('allowclear')
    public allowclear: string,
    @Attribute('hiddenLabel') public hiddenLabel: string,
    @Attribute('placeholder') public placeholder: string,
    @Attribute('required') public required: string
  ) {
    super();
    searchAPI<LeaveTypeModel>(API.leaveType)
      .subscribe(({ object }) => {
        this.dataSource = object
          .map(data => {
            return {
              id: data.leaveTypeId,
              text: data.leaveTypeNameTH
            };
          });
      });
  }
}
