import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === undefined || value === null) {
      return;
    }
    let a = value.toString();
    a = a.replace(/-/g, ''); /// [a-z.]+/
    if (a.length < 9) {
      return a;
    }
    const b = a.length;
    const s1 = a.slice(0, b - 7);
    const s2 = a.slice(b - 7, b - 4);
    const s3 = a.slice(b - 4, b);
    a = `${s1}-${s2}-${s3}`;
    return a;
  }

}
