import {
  RouterModule,
  Routes,
} from '@angular/router';

import { NavigationComponent } from './core/base-component/navigation/navigation.component';

const appRoutes: Routes = [
  {
    path: 'login',
    loadChildren: './page/login/login.module#LoginModule'
  },
  {
    path: 'resetpassword',
    loadChildren: './page/forget-password/forget-password.module#ForgetPasswordModule'
  },
  {
    path: '',
    component: NavigationComponent,
    children: [


      {
        path: 'setup',
        // canActivate: [AuthGuard],
        // data: {
        //   structureCode: 'S01'
        // },
        loadChildren: './page/setup/setup.module#SetupModule'
      },











      {
        path: '**',
        redirectTo: 'setup',
        pathMatch: 'full'
      }
    ]
  }
];

export const AppRoutingModule = RouterModule.forRoot(
  appRoutes,
  {
    enableTracing: false,
    useHash: true
  } // <-- debugging purposes only
);


