import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  OnInit,
} from '@angular/core';
import { MessageService } from '@service/message.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  animations: [
    trigger('messageInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(0, 200%, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class MessageComponent implements OnInit {

  public messageState = 'out';
  public countDown = 5;
  public value = 0;
  public message: string;

  private countdownInterval: any;
  private valueInterval: any;

  constructor(private msgService: MessageService) { }

  ngOnInit() {
    this.msgService.on()
      .subscribe(data => {

        clearInterval(this.valueInterval);
        clearInterval(this.countdownInterval);

        if (data.close) {
          this.messageState = 'out';
        } else {
          this.countDown = 5;
          this.value = 0;
          this.messageState = 'in';
          this.message = data.message;

          this.countdownInterval = setInterval(() => {
            if (this.countDown <= 1) {
              clearInterval(this.countdownInterval);
              this.messageState = 'out';
            }
            this.countDown--;

          }, 1000);

          this.valueInterval = setInterval(() => {
            if (this.countDown >= 100) {
              clearInterval(this.valueInterval);
            }
            this.value++;
          }, 50);
        }
      });
  }

  closeMessage() {
    this.msgService.fire({ close: true });
  }
}
