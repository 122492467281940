import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Select2Module } from '@modules/select2';

import { MaterialModule } from '../material/mat.module';
import { CustomSelect2Module } from '../select2/customSelect2.module';
import {
  FormEmployeeTypeComponent,
  FormHolidayComponent,
  FormLeaveGroupComponent,
  FormLeaveTypeComponent,
  FormOrgDepartmentComponent,
  FormOrgPositionComponent,
} from './component';
import { FormSearchModule } from './component/form-search/form-search.module';
import { FormMasterComponent } from './form-master/form-master.component';

@NgModule( {
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule.withConfig( { warnOnNgModelWithFormControl: 'never' } ),
    MaterialModule,
    Select2Module,
    FormSearchModule,
    CustomSelect2Module
  ],
  declarations: [
    // From
    FormMasterComponent,
    FormOrgDepartmentComponent,
    FormOrgPositionComponent,
    FormEmployeeTypeComponent,
    FormLeaveGroupComponent,
    FormLeaveTypeComponent,
    FormHolidayComponent
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    Select2Module,
    CustomSelect2Module,
    FormSearchModule,
    // Form
    FormMasterComponent,
    FormOrgDepartmentComponent,
    FormOrgPositionComponent,
    FormEmployeeTypeComponent,
    FormLeaveGroupComponent,
    FormLeaveTypeComponent,
    FormHolidayComponent
  ],
  entryComponents: [
    FormOrgPositionComponent,
    FormEmployeeTypeComponent,
    FormLeaveGroupComponent,
    FormLeaveTypeComponent,
    FormHolidayComponent
  ]
} )
export class FormMasterModule { }
