import {
  Attribute,
  Component,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { API } from '@environment';
import { PositionModel } from '@model/base/org/PositionModel';
import { searchAPI } from '@modules/backend';
import { Select2Data } from '@modules/select2';

import { NgModelBaseSelect } from '../../base-class/select2-base-class';

@Component({
  selector: 'app-positions-select2',
  template: `
    <div class="field">
      <mat-label *ngIf="hiddenLabel == null">
        ตำแหน่ง
        <span
          *ngIf="required != null"
          class="text-danger"
        >*</span>
      </mat-label>
      <select2
        [(ngModel)]="values"
        [dataSource]="dataSource"
        [placeholder]="placeholder"
        [attr.allowclear]="allowclear"
      ></select2>
    </div>
  `,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PositionsSelect2Component,
    multi: true
  }]
})
export class PositionsSelect2Component extends NgModelBaseSelect {
  dataSource: Select2Data[];

  constructor(
    @Attribute('allowclear')
    public allowclear: string,
    @Attribute('hiddenLabel')
    public hiddenLabel: string,
    @Attribute('placeholder')
    public placeholder: string,
    @Attribute('required')
    public required: string,
  ) {
    super();
    searchAPI<PositionModel>(API.position)
      .subscribe(({ object }) => {
        this.dataSource = object
          .map(data => {
            return {
              id: data.positionId,
              text: data.positionNameTH
            };
          });
      });
  }
}
