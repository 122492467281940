import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material';
import { BroadcasterService } from '@service/broadcaster.service';
import { ModelService } from '@service/model.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [ModelService],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(200)
      ]),
      transition('* => void', [
        animate(200, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class ModalComponent implements OnInit {
  currentComponent;
  onConfirm: Function;
  data;
  myInjector: Injector;

  @Input()
  title: string;

  @Input()
  visible: boolean;

  @Output()
  submit = new EventEmitter<any>();

  @Output()
  cancel = new EventEmitter<any>();

  constructor(private broadcast: BroadcasterService, public injector: Injector,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.broadcast.on<any>(ModalComponent)
      .subscribe(data => {
        this.title = data.title;
        this.data = data.data;
        this.onConfirm = data.callBack;
        if (data.component) {
          this.currentComponent = data.component;
          // this.myInjector = ReflectiveInjector.resolveAndCreate([ModelService], this.injector);
          this.openDialog({
            title: this.title,
            data: JSON.parse(JSON.stringify(this.data)),
            request: data.request,
            component: this.currentComponent,
            // buttonLabel: data.buttonLabel === undefined ? ['บันทึก', 'ปิด'] : data.buttonLabel
            buttonLabel: data.buttonLabel || []
          });
        } else {
          this.currentComponent = null;
        }
      });

  }

  public closeModal() {
    this.visible = false;
  }

  onCancelClick() {
    this.visible = false;
    this.cancel.emit(null);
  }

  onSubmitClick() {
    this.onConfirm(this.data);
    this.visible = false;
    this.submit.emit(null);
  }
  public openModal() {
    this.visible = true;
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(DialogComponent, {
      //  Width: '100%',
      panelClass: 'modal-form',
      maxWidth: 800,
      id: 'dialog-form',
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== '') {
        this.data = data.data;
        this.onSubmitClick();
        // HWD Service
        console.log(data);
      }
    });
  }
}

@Component({
  selector: 'app-dialog-component',
  templateUrl: 'dialog-component.html',
})
export class DialogComponent implements OnInit, AfterViewInit {
  currentComponent;
  title;
  showBtnSave = false;
  request = [];
  buttonLabel = [];

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModel,
    private modelService: ModelService) {
    this.buttonLabel = data.buttonLabel;
  }

  ngOnInit() {
    this.title = this.data.title;
    this.currentComponent = this.data.component;
    this.modelService.setValue(this.currentComponent, this.data.data);
    if (this.data.request !== undefined) {
      this.request = this.data.request;
      this.onMouseMove();
    }

  }
  ngAfterViewInit() {
    // this.currentComponent = this.data.component;
  }

  @HostListener('document:mousemove')
  @HostListener('document:keyup')
  onMouseMove() {
    for (let i = 0; i < this.request.length; i++) {
      if (this.data.data[this.request[i]] === '' ||
        this.data.data[this.request[i]] === null ||
        this.data.data[this.request[i]] === undefined) {
        this.showBtnSave = true;
        return;
      }
    }
    this.showBtnSave = false;
  }

}


export interface DialogModel {
  title: string;
  data: any;
  request: string[];
  component: any;
  buttonLabel?: string[];
}
