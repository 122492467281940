import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  OnInit,
} from '@angular/core';
import { BroadcasterService } from '@service/broadcaster.service';

@Component({
  selector: 'app-slide-bar',
  templateUrl: './slide-bar.component.html',
  styleUrls: ['./slide-bar.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition('void => *', [
        style({ transform: 'translate3d(100%, 0, 0)' }),
        animate(200)
      ]),
      transition('* => void', [
        animate(200, style({ transform: 'translate3d(100%, 0, 0)' }))
      ])
    ])
  ]
})
export class SlideBarComponent implements OnInit {

  public menuState = 'out';
  public visible = false;
  constructor(private broadcast: BroadcasterService) { }

  ngOnInit() {
    this.broadcast.on<any>(SlideBarComponent)
      .subscribe(() => {
        this.toggleSlidBar();
      });
  }

  toggleSlidBar() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    this.visible = !this.visible;
  }
}
