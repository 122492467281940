import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { API } from '@environment';
import { updateAPI } from '@modules/backend';
import { NotificationService } from '@service/notification.service';

import { NgModelBase } from '../../base-class/base-class';

export const EPANDED_CUSTOM_FORM_CONTACT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomFormContactComponent),
  multi: true
};

@Component({
  selector: 'app-custom-form-contact',
  templateUrl: './custom-form-contact.component.html',
  styles: [`mat-form-field {
    width: 100%;
}`],
  providers: [EPANDED_CUSTOM_FORM_CONTACT_VALUE_ACCESSOR]
})
export class CustomFormContactComponent extends NgModelBase implements OnInit {
  @Input() setOfficePhone = true;
  @Input() formType = 'edit';
  @Output() updateSuccess: EventEmitter<boolean> = new EventEmitter();
  editing = false;

  officePhone = new FormControl('', [Validators.required]);
  mobile = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  constructor(private notify: NotificationService) {
    super();
  }
  getErrorMessage(value: string) {
    switch (value) {
      case 'officePhone':
        return 'โปรดระบุ หมายเลขโทรศัพท์สำนักงาน';
      // break;
      case 'mobile':
        return 'โปรดระบุ หมายเลขโทรศัพท์เคลื่อนที่';
      // break; pattern
      case 'email':
        return 'โปรดระบุ อีเมล์';
      // break; Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')
      default:
        break;
    }
  }

  ngOnInit() { }
  onUpdate() {
    updateAPI(API.contact, this.value)
      .subscribe(this.catchResult('บันทึกข้อมูลติดต่อ'));
  }


  private catchResult(message: string) {
    this.notify.info(`กำลัง${message}`);

    return {
      next: () => {
        this.editing = false;
        this.updateSuccess.emit(true);
        this.notify.success(`${message} แล้ว`);
      },
      error: () => this.notify.error(`ไม่สามารถ${message}`)
    };
  }
}
