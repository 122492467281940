import { DEFAULT_REQUEST_OPTIONS } from '@modules/backend';

import { Permission } from './permission';
import { Session } from './session';

export const environment = {
  production: false
};

export const HOST = 'http://192.168.1.62:8555';
export const VIEW_URL = 'http://192.168.1.62:8555/api/file/v1/view/';
export const permission = new Permission();
export const session = new Session();


export const enum API {
  login = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/login',
  page = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/page',
  customer = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/customer',
  customerLogicCreate = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bpm/customer/createmember',
  saveLogicInvoiceDocument = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bpm/bill/savebill',
  memberAccount = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/memberaccount',
  paymentLogic = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bpm/bill/payment',
  selectMember = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bpm/customer/selectmember',
  cancelBill = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bpm/bill/cancelbill',
  verifypincode = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bpm/employee/verifypincode',
  verifypassword = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bpm/employee/verifypassword',
  isMemberCodeDuplicate = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bpm/customer/membercode',
  updateStatusPlace = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bpm/place/updatestatus',
  cancelmember = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bpm/bill/cancelmember',
  // setup
  floorPlan = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/floorplan',
  zonePlan = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/zoneplan',
  foodType = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/foodtype',
  foodCategory = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/foodcategory',
  food = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/food',
  productBrand = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/productbrand',
  productCategory = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/productcategory',
  product = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/product',
  unit = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/unit',
  service = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/service',
  memberPackage = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/memberpackage',
  serviceChargeTemplate = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/servicechargetemplate',
  serviceDrinkType = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/servicedrinktype',
  position = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/position',
  shiftWork = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/shiftwork',
  shiftWorkLine = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/shiftworkline',
  employee = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/employee',
  serviceCharge = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/servicechargetemplate',
  permissionGroup = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/permissiongroup',
  modeling = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/modeling',
  place = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/place',
  invoiceDocument = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/invoicedocument',
  moveTable = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bpm/bill/moveplace',
  creditCardType = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/creditcardtype',
  printBill = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/report/printbill',
  printReceive = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/report/printreceive',
  bankAccount = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bankaccount',
  bank = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/bank',
  memberPackageCategory = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/packagecategory',
  memberPackageGroup = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/packagegroup',
  salesshift = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/salesshift',
  authentication = 'http://103.13.31.63:8555/restaurant/api/restaurant/v1/authentication',



  province = 'http://192.168.1.62:8555/api/hr/addressMaster/province',
  amphur = 'http://192.168.1.62:8555/api/hr/addressMaster/amphur',
  district = 'http://192.168.1.62:8555/api/hr/addressMaster/district',
  address = 'http://192.168.1.62:8555/api/hr/v1/address',
  contact = 'http://192.168.1.62:8555/api/hr/v1/contact',
  department = 'http://192.168.1.62:8555/api/org/v1/department',
  organization = 'http://192.168.1.62:8555/api/org/v1/organization',
  departmentPosition = 'http://192.168.1.62:8555/api/org/v1/department/position',
  employeelevel = 'http://192.168.1.62:8555/api/org/v1/employeelevel',
  personnelType = 'http://192.168.1.62:8555/api/hr/v1/personneltype',
  leaveType = 'http://192.168.1.62:8555/api/hr/v1/leavetype',
  leaveGroup = 'http://192.168.1.62:8555/api/hr/v1/leavegroup',
  leaveLine = 'http://192.168.1.62:8555/api/hr/v1/leaveline',
  periodTime = 'http://192.168.1.62:8555/api/hr/v1/leaveline/periodtime',
  personnel = 'http://192.168.1.62:8555/api/hr/v1/personnel',
  personnelEducation = 'http://192.168.1.62:8555/api/hr/v1/education',
  personnelContract = 'http://192.168.1.62:8555/api/hr/v1/personnelcontract',
  personnelContractDraft = 'http://192.168.1.62:8555/api/hr/v1/personnelcontract/draft',
  personnelContractResign = 'http://192.168.1.62:8555/api/hr/v1/personnelcontract/resign',
  personnelCareerhistory = 'http://192.168.1.62:8555/api/hr/v1/careerhistory',
  personnelSpecialAbility = 'http://192.168.1.62:8555/api/hr/v1/personnelspecialability',
  personnelfamily = 'http://192.168.1.62:8555/api/hr/v1/family',
  personnelfamilyMember = 'http://192.168.1.62:8555/api/hr/v1/familymember',
  personnelInsurance = 'http://192.168.1.62:8555/api/hr/v1/personnelinsurance',
  personnelLanguage = 'http://192.168.1.62:8555/api/hr/v1/languageskill',
  personnelLeavebalance = 'http://192.168.1.62:8555/api/hr/v1/leavebalance/personnel',
  personnelWorkStatus = 'http://192.168.1.62:8555/api/hr/v1/personnel/workstatus',
  personnelRecord = 'http://192.168.1.62:8555/api/hr/v1/personnelrecord',
  commander = 'http://192.168.1.62:8555/api/hr/v1/personnel/commander',
  postJob = 'http://192.168.1.62:8555/api/hr/v1/postjob',
  postJobPublic = 'http://192.168.1.62:8555/api/hr/v1/postjob/public',
  application = 'http://192.168.1.62:8555/api/hr/v1/applicationform',
  applicationConfirm = 'http://192.168.1.62:8555/api/hr/v1/applicationform/confirm',
  applicationInterview = 'http://192.168.1.62:8555/api/hr/v1/interview',
  leaveDocument = 'http://192.168.1.62:8555/api/hr/v1/leavedocument',
  fileUpload = 'http://192.168.1.62:8555/api/file/v1/upload',
  fileDetail = 'http://192.168.1.62:8555/api/file/v1/detail',
  leaveBalance = 'http://192.168.1.62:8555/api/hr/v1/leavebalance',
  overtime = 'http://192.168.1.62:8555/api/hr/v1/overtime',
  changeworkinghours = 'http://192.168.1.62:8555/api/hr/v1/changeworkinghours',
  personnelapprovaldocument = 'http://192.168.1.62:8555/api/hr/v1/personnelapprovaldocument',
  personnelapprovaldocumentDocument = 'http://192.168.1.62:8555/api/hr/v1/personnelapprovaldocument/document',
  documentapprovaltemplate = 'http://192.168.1.62:8555/api/hr/v1/documentapprovaltemplate',
  signature = 'http://192.168.1.62:8555/api/hr/v1/signature',
  reportApplicationForm = 'http://192.168.1.62:8555/api/hr/v1/applicationform/report',
  resignationReport = 'http://192.168.1.62:8555/api/hr/v1/personnelcontract/download/resignationreport',
  deliveremployee = 'http://192.168.1.62:8555/api/hr/v1/personnelcontract/download/deliveremployee',
  holiday = 'http://192.168.1.62:8555/api/hr/v1/holiday',
  notification = 'http://192.168.1.62:8555/api/hr/v1/notification',
  changePassword = 'http://192.168.1.62:8555/api/authen/changepassword',
  structure = 'http://192.168.1.62:8555/api/hr/v1/structure',
  timeattendance = 'http://192.168.1.62:8555/api/hr/v1/timeattendance',
  fingerprintEnroll = 'http://192.168.1.62:8555/api/fingerprint/v1/enroll',
  fingerprintList = 'http://192.168.1.62:8555/api/fingerprint/v1/fingerprintlist',
  fingerprintDelete = 'http://192.168.1.62:8555/api/fingerprint/v1/delete',
  loginWithFingerprint = '/api/fingerprint/v1/identify',
  orgAllowAccessTimeStamp = 'http://192.168.1.62:8555/api/hr/v1/timeattendance/organization',
  fingerprintscan = 'http://192.168.1.62:8555/api/hr/v1/timeattendance/fingerprintscan',
  identifyFingerprint = 'http://192.168.1.62:8555/api/fingerprint/v1/identify',
  productStock = 'http://192.168.1.62:8555/api/ic/v1/inventory',
  productUnit = 'http://192.168.1.62:8555/api/ic/v1/unit',
  commissionType = 'http://192.168.1.62:8555/api/spm/v1/commissiontype',
  expertiseLevel = 'http://192.168.1.62:8555/api/spm/v1/expertiselevel',
  customerGroup = 'http://192.168.1.62:8555/api/crm/v1/customergroup',
  premiumCardType = 'http://192.168.1.62:8555/api/crm/v1/premiumcardtype',
  publicityChannel = 'http://192.168.1.62:8555/api/crm/v1/publicitychannel',
  snackAndDrinks = 'http://192.168.1.62:8555/api/crm/v1/snackanddrinks',
  serviceType = 'http://192.168.1.62:8555/api/spm/v1/servicetype',
  serviceCategory = 'http://192.168.1.62:8555/api/spm/v1/servicecategory',
  serviceTime = 'http://192.168.1.62:8555/api/spm/v1/servicetime',
  EmployeeModel = 'EmployeeModel'
}

export const enum SOCKJS {
  hr = 'http://192.168.1.62:1103/hdw',
  restaurant = 'http://192.168.1.62:8555/hdw',
  timeAttendanceSystem = 'http://192.168.1.62:8555/hdw',
  fingerprintRegister = 'http://192.168.1.62:8555/hdw',
  loginWithFingerprint = 'http://192.168.1.62:8555',
}


export const enum UPLOAD {
  postJob = 'tk/hr/postjob',
  leaveDocument = 'tk/hr/leavedocumnet',
  applicationForm = 'tk/hr/application',
  company = 'tk/setup/organization',
  PremiumCardType = 'tk/setup/premium-card-type',
  snackAndDrinks = 'tk/setup/snack-and-drinks',
  serviceType = 'tk/setup/service-type',
  serviceCategory = 'tk/setup/service-category',
  customer = '',
}

export const enum DOCUMENT_TYPE {
  leave = 2,
  contract = 5,
  overtTime = 7,
  changeWorkingHours = 8
}

export const enum DOCUMENT_STATUS {
  draft = 1,
  pending = 2,
  approve = 3,
  reject = 4,
  cancel = 5
}

export const enum POSITION_EMP {
  sale = 3
}

DEFAULT_REQUEST_OPTIONS.sending = (xhr, body) => {
  xhr.withCredentials = true;
  xhr.setRequestHeader('Authorization', session.get('token'));

  if (body != null && typeof body === 'object' && !(body instanceof FormData)) {
    xhr.setRequestHeader('Content-Type', 'application/json');
    body = JSON.stringify(body);
  }

  return body;
};

DEFAULT_REQUEST_OPTIONS.receiving = (observer, response) => {
  const statusCode = response.statusCode || 422;

  statusCode === 200 ? observer.next(response) : observer.error(statusCode);
};
