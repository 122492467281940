export class Permission {
  canAdd: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  canView: boolean;

  fromPermissionCode(permissionCode: string) {
    const code = Number(`0x${permissionCode}`);

    this.canAdd = (code & 8) === 8;
    this.canUpdate = (code & 4) === 4;
    this.canDelete = (code & 2) === 2;
    this.canView = (code & 1) === 1;

    return this;
  }
}
