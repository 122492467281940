import { ControlValueAccessor } from '@angular/forms';

const noop = () => { };

export class NgModelBaseSelect implements ControlValueAccessor {
  // The internal data model
  private innervalues: any = '';

  // Placeholders for the callbacks which are later provided
  // by the Control values Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  get values(): any {
    return this.innervalues;
  }

  // set accessor including call the onchange callback
  set values(v: any) {
    if (v !== this.innervalues) {
      this.innervalues = v;
      this.onChangeCallback(v);
    }
  }

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  // From ControlvaluesAccessor interface
  writeValue(values: any) {
    if (values !== this.innervalues) {
      this.innervalues = values;
    }
  }

  // From ControlvaluesAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlvaluesAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
