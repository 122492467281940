import { Injectable } from '@angular/core';
import {
  NavigationStart,
  Router,
} from '@angular/router';
import {
  Alert,
  AlertType,
} from '@model/notification/notification-model';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private subject = new Subject<Alert>();
  private keepAfterRouteChange = false;

  constructor(router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  asObservable() {
    return this.subject.asObservable();
  }

  success(message: string, keepAfterRouteChange = true) {
    this.alert(AlertType.Success, message, keepAfterRouteChange);
  }

  error(message: string, keepAfterRouteChange = true) {
    this.alert(AlertType.Error, message, keepAfterRouteChange);
  }

  info(message: string, keepAfterRouteChange = true) {
    this.alert(AlertType.Info, message, keepAfterRouteChange);
  }

  warn(message: string, keepAfterRouteChange = true) {
    this.alert(AlertType.Warning, message, keepAfterRouteChange);
  }
  startSpinner() {
    this.spinner(true);
  }
  stopSpinner() {
    this.spinner(false);
  }
  private spinner(value) {
    this.subject.next(<any>{ spinner: value });
  }
  alert(type: AlertType, message: string, keepAfterRouteChange = true) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next(<Alert>{ type: type, message: message });
  }

  clear() {
    // clear alerts
    this.subject.next();
  }
}
