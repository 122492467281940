import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styles: [`mat-form-field {
    width: 100%;
}`]
})
export class ContactDetailComponent implements OnInit {

  @Input() data;

  contact = null;
  ngOnInit() {
    this.contact = this.data;
    // if (this.dataId) {
    //   this.contact = await this.fetch(this.dataId)
    // }
  }

  fetch(id) {
    // return this.ERPAPI.get('hr/contact/' + id).then(res => res.object)
  }
}
