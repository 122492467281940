import {
  Attribute,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';

interface SearchDataModel {
  active?: boolean;
  narmalSearch?: SDataModel[];
  advanceSearch?: FieldDataModel[];
  focusSearch?: boolean;
}
interface FieldDataModel {
  name?: string;
  type?: string;
  field?: string;
  value?: string;
  data?: SDataModel[];
}
interface SDataModel {
  text: string;
  value: string;
}
@Component({
  selector: 'app-form-search',
  templateUrl: './form-search.component.html',
  styleUrls: ['./form-search.component.scss']
})
export class FormSearchComponent implements OnInit {
  @ViewChild('searchName') nameField: ElementRef;
  @Input() data: SearchDataModel = { narmalSearch: [], advanceSearch: [], focusSearch: false };
  @Output() valueChange = new EventEmitter<any>();
  narmalSelected: SDataModel;
  adVanceSelect: FieldDataModel[] = [];
  searchValue;
  constructor(
    @Attribute('custom')
    public custom: string
  ) {
  }
  ngOnInit() {
    if (this.data.narmalSearch === undefined) {
      this.data.narmalSearch = [];
    } else {
      this.narmalSelected = this.data.narmalSearch[0];
    }
    if (this.data.advanceSearch === undefined) {
      this.data.advanceSearch = [];
    }

  }


  setNarmalSelected(value) {
    this.narmalSelected = value;
  }
  setAdVanceSelect(value) {
    this.adVanceSelect = value;
  }
  onKeyupSearch(event) {
    this.submitSearch();
  }
  submitSearch() {
    const tempData = {
      active: false,
      narmalSearch: {},
      adVanceSelect: []
    };
    if (this.narmalSelected) {
      tempData.narmalSearch = { field: this.narmalSelected.value, value: this.searchValue };
    }
    if (this.adVanceSelect) {
      tempData.adVanceSelect = this.adVanceSelect;
    }
    if (this.searchValue !== '') {
      tempData.active = true;
    }
    this.valueChange.emit(tempData);
  }

  onSearchChange(event) {
    const tempData = {
      active: false,
      narmalSearch: {},
      adVanceSelect: []
    };
    if (event === '') {
      this.valueChange.emit(tempData);
    }
  }
  adValueChange(event, field) {
    for (let i = 0; i < this.adVanceSelect.length; i++) {
      if (this.adVanceSelect[i].field === field) {
        this.adVanceSelect[i].value = event.value;
        return;
      }
    }
    this.adVanceSelect.push({ field: field, value: event.value });

  }
}
