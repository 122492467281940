import {
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  Validators,
} from '@angular/forms';
import { ModelService } from '@service/model.service';

import { NgModelBase } from '../../../base-class/base-class';

@Component({
  selector: 'app-form-leave-group',
  templateUrl: './form-leave-group.component.html',
  styleUrls: ['./form-leave-group.component.css']
})
export class FormLeaveGroupComponent extends NgModelBase implements OnInit {
  leaveGroupNameEN = new FormControl('', [Validators.required]);
  leaveGroupNameTH = new FormControl('', [Validators.required]);
  limitDayLeave;
  selectEdit = null;
  dumpLeaveGroupType = {
    id: null,
    limitDayLeave: null,
    leaveType: {}
  };
  indexOfLeaveGroupType = 0;
  constructor(private modelService: ModelService) {
    super();
    const cptType = this.modelService.getValue();
    if (cptType.cptType.name === FormLeaveGroupComponent.name) {
      this.value = cptType.data;
    }
  }

  ngOnInit() {

  }
  @HostListener('click', ['$event'])
  onClick(btn) {
    if (this.selectEdit !== null) {
      btn.path.forEach(element => {
        if (parseFloat(element.id) !== this.selectEdit && element.nodeName === 'TR') {
          this.selectEdit = null;
        }
      });
    }
  }
  selectEdited(id) {
    if (id === this.selectEdit) {
      this.selectEdit = null;
    } else {
      if (id) {
        this.selectEdit = id;
      }
    }
  }
  getLastID() {
    for (let i = 0; i < this.value.leaveGroupType.length; i++) {
      const element = this.value.leaveGroupType[i];
      if (element.id >= this.indexOfLeaveGroupType) {
        this.indexOfLeaveGroupType = element.id;
      }
    }
    return this.indexOfLeaveGroupType = this.indexOfLeaveGroupType + 1;
  }
  newLeavid(setValue) {
    // TODO Migrate Select2Data
    this.dumpLeaveGroupType.leaveType = setValue.LeaveType;

  }
  newLeave() {
    if (this.limitDayLeave > 0) {
      this.dumpLeaveGroupType.id = this.getLastID();
      this.dumpLeaveGroupType.limitDayLeave = parseFloat(this.limitDayLeave);
      this.value.leaveGroupType.push(JSON.parse(JSON.stringify(this.dumpLeaveGroupType)));
    }
  }
  deleteLeave(id) {
    const element = this.value.leaveGroupType;
    for (let i = 0; i < element.length; i++) {
      if (element[i].id === id) {
        element.splice(i, 1);
      }
    }

  }
  setLeavid(id, setValue) {
    for (let i = 0; i < this.value.leaveGroupType.length; i++) {
      const element = this.value.leaveGroupType[i];
      if (element.id === id) {
        element.limitDayLeave = parseFloat(setValue.value);
        element.leaveType = setValue.LeaveType;
        return;
      }
      // if (this.value.leaveGroupType.length === i) {
      //   this.value.leaveGroupType.push({ id: this.indexOfLeaveGroupType + 2, limitDayLeave: parseFloat(setValue.value) });
      //   this.value.leaveGroupType[this.indexOfLeaveGroupType].leaveType = setValue.LeaveType;
      //   return;
      // }
    }
  }
  getErrorMessage(value: string) {
    switch (value) {
      case 'leaveGroupNameTH':
        return this.leaveGroupNameTH.hasError('required') ? 'ระบุชื่อประเภทพนักงาน (TH)' : '';
      // break;
      case 'leaveGroupNameEN':
        return this.leaveGroupNameEN.hasError('required') ? 'ระบุชื่อประเภทพนักงาน (EN)' : '';
      // break;
      default:
        break;
    }
  }
}

