import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'Men' || '0' || 0:
        return 'ชาย';
      case 'Women' || '1' || 1:
        return 'หญิง';
      default:
        return value;
    }
  }
}
