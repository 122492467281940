import { Injectable } from '@angular/core';
import { BroadcasterService } from './broadcaster.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private broadcaster: BroadcasterService) { }

  fire(data: any): void {
      this.broadcaster.broadcast(MessageEvent, data);
  }

  on(): Observable<any> {
      return this.broadcaster.on<string>(MessageEvent);
  }

}
