import {
  Component,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { NgModelBase } from '../../base-class/base-class';

export const EPANDED_CUSTOM_FORM_ADDRESS_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ApplicationDetailComponent),
  multi: true
};

@Component({
  selector: 'app-application-detail',
  templateUrl: './application-detail.component.html',
  styleUrls: ['./application-detail.component.scss'],
  providers: [EPANDED_CUSTOM_FORM_ADDRESS_VALUE_ACCESSOR]
})
export class ApplicationDetailComponent extends NgModelBase {


}
