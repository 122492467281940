import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PagingModel } from '@model/paging/paging-model';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss']
})
export class PagingComponent implements OnInit {
  @Input() paging: PagingModel;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onPageChange = new EventEmitter<any>();

  totalPage: number;
  showPage: number[];

  disabeledForward = false;
  disabeledReward = false;
  constructor() { }

  ngOnInit() {
    const totalPage = Math.ceil(this.paging.totalDataSize / this.paging.totalPerPage);
    if (totalPage >= 1) {
      this.totalPage = totalPage;
    } else {
      this.totalPage = 1;
    }
    this.calculatePage(false);
  }

  calculatePage(isChange: boolean) {
    this.showPage = [];
    if (this.paging.cuerrntPage <= 3) {
      for (let i = 1; i <= this.totalPage; i++) {
        this.showPage.push(i);
      }
      // this.showPage = [1, 2, 3, 4, 5];
    } else if (this.paging.cuerrntPage > this.totalPage - 3) {
      for (let index = this.totalPage - 4; index <= this.totalPage; index++) {
        this.showPage.push(index);
      }
    } else {
      for (let index = this.paging.cuerrntPage - 2; index <= this.paging.cuerrntPage + 2; index++) {
        this.showPage.push(index);
      }
    }
    // tslint:disable-next-line:triple-equals
    if (this.paging.cuerrntPage == 1) {
      this.disabeledReward = true;
    } else {
      this.disabeledReward = false;
    }

    // tslint:disable-next-line:triple-equals
    if (this.paging.cuerrntPage == this.totalPage) {
      this.disabeledForward = true;
    } else {
      this.disabeledForward = false;
    }
    if (isChange) {
      this.onPageChange.emit(this.paging.cuerrntPage);
    }
  }

  selectPage(page) {
    this.paging.cuerrntPage = page;
    this.calculatePage(true);
  }

  forward() {
    if (!this.disabeledForward) {
      this.paging.cuerrntPage++;
      this.calculatePage(true);
    }
  }

  reward() {
    if (!this.disabeledReward) {
      this.paging.cuerrntPage--;
      this.calculatePage(true);
    }
  }

  first() {
    if (!this.disabeledReward) {
      this.paging.cuerrntPage = 1;
      this.calculatePage(true);
    }
  }

  last() {
    if (!this.disabeledForward) {
      this.paging.cuerrntPage = this.totalPage;
      this.calculatePage(true);
    }
  }

}
