import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModelService {
  public data: any;
  public setValue(cptType, param) {
    this.data = { cptType: cptType, data: param };
  }
  public getValue() {
    return this.data;
  }

}
