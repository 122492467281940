import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenderPipe } from './gender.pipe';
import { MarryStatusPipe } from './marryStatus.pipe';
import { InitialsPipe } from './initials.pipe';
import { PhonePipe } from './phone.pipe';
import { IdcardPipe } from './idcard.pipe';
import { NumberFormatPipe } from './numberFormat.pipe';
import { MilitaryStatusPipe } from './militaryStatus.pipe';
import { YearDiffPipe } from './yearDiff.pipe';
import { CheckNullPipe } from './checkNull.pipe';
import { WorkStatusPipe } from './workStatus.pipe';
import { StatusPipe } from './status.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    GenderPipe,
    MarryStatusPipe,
    InitialsPipe,
    PhonePipe,
    IdcardPipe,
    NumberFormatPipe,
    MilitaryStatusPipe,
    YearDiffPipe,
    CheckNullPipe,
    WorkStatusPipe,
    StatusPipe
  ],
  exports: [
    GenderPipe,
    MarryStatusPipe,
    InitialsPipe,
    PhonePipe,
    IdcardPipe,
    NumberFormatPipe,
    MilitaryStatusPipe,
    YearDiffPipe,
    CheckNullPipe,
    WorkStatusPipe,
    StatusPipe
  ]
})
export class PipesCustomModule { }
