import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { OptionsModel } from '@model/options/options-model';

@Component({
  selector: 'auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit {

  @Input() value: string;
  @Input() url: string;
  @Input() source: OptionsModel[];
  @Output() valueChange = new EventEmitter<any>();
  public isLoaded = false;
  public displayValue: string;
  public options: OptionsModel[];
  public show = false;
  public dataSource: OptionsModel[];

  constructor() { }

  ngOnInit() {
    this.loadData();

  }

  loadData() {
    this.isLoaded = false;
    setTimeout(() => {
      if (this.url) {
        this.initAutocomplete();
      } else {
        this.dataSource = this.source;
        this.initAutocomplete();
      }
      this.isLoaded = true;
    }, 4000);
  }

  initAutocomplete() {
    if (this.dataSource) {
      this.options = this.dataSource;
      // tslint:disable-next-line:triple-equals
      const tmp = this.options.find(x => x.value == this.value);
      if (tmp) {
        this.displayValue = tmp.text;
      }
      this.filterOptions();
    }
  }

  focusFunction() {
    console.log(1);
    this.show = !this.show;
  }
  focusOutFunction() {
    setTimeout(() => {
      this.show = !this.show;
    }, 100);

  }

  filterOptions() {
    if (this.dataSource) {
      this.options = this.dataSource.filter(option => option.text.indexOf(this.displayValue) >= 0);
    }
  }

  itemSelected(evt: any) {
    console.log(evt.option.value);
  }

  select(value) {
    this.displayValue = value.text;
    this.valueChange.emit(value);
  }

}
