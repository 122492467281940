import {
  Component,
  OnInit,
} from '@angular/core';
import { BroadcasterService } from '@service/broadcaster.service';

@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.css']
})
export class BasePageComponent implements OnInit {

  public componentIsReady = false;
  public broadcastList = [];
  constructor(public broadcaster: BroadcasterService) { }

  ngOnInit() {
    this.registerBroadcast();
    this.init();
  }

  init() { }

  registerBroadcast() {
    this.broadcastList.forEach(element => {
      this.broadcaster.on<boolean>(element)
        .subscribe(() => {
        });
    });
  }

}
